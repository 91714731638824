
import FamiliaInfo from "@/components/FamiliaInfo.vue";
import comarcas from "@/core/data/comarcas";
import router from "@/router";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import moment from "moment";
import "moment/locale/es";
import { useField } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { Vue3Lottie } from "vue3-lottie";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "kt-landing",
  components: {
    Vue3Lottie,
    FamiliaInfo,
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.act;
    const queryComarca = route.query.comarcaId;

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);

    const loading = ref(false);

    const { value: comarca } = useField("comarca");

    if (queryComarca) {
      comarca.value = queryComarca;
    }

    const datosServicios = computed(() => module.servicios);

    onBeforeMount(() => {
      loading.value = true;
      module
        .fetchByComarcaId({ comarcaId: comarca.value as string })
        .then(() => (loading.value = false));
    });

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    watch(comarca, () => {
      loading.value = true;
      if (comarca.value) {
        router.push("servicios-colectivos?comarcaId=" + comarca.value);
      } else {
        router.push("servicios-colectivos");
      }
      module
        .fetchByComarcaId({ comarcaId: comarca.value as string })
        .then(() => (loading.value = false));
    });

    return {
      i18n,
      token,
      comarca,
      comarcas,
      datosServicios,
      moment,
      loading,
    };
  },

  methods: {
    onItemTap(item) {
      window.location.href =
        window.location.origin + "/#/home?act=" + item.formularioId;
    },
  },
});
